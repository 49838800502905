import { useState, useEffect } from "react"

import * as api from "../../../api"

//------------------------------------------------------------------------------

export default function useRestTable(resourceUrl, token, options) {
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [pagination, setPagination] = useState({
    current: (options && options.current) || 1,
    pageSize: (options && options.pageSize) || 10,
  })

  const [filters, setFilters] = useState((options && options.filters) || {})

  // -------------------------------------

  // side effect: monitor page changing
  // also executed on hook mounting
  useEffect(() => {
    fetchList()

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    fetchList()

    // eslint-disable-next-line
  }, [filters])

  // -------------------------------------

  function fetchList(params) {
    const pageNumber =
      (params && params.pagination && params.pagination.current) ||
      pagination.current

    const options = {
      pageNumber,
      pageSize: pagination.pageSize,
      filters,
      ...params,
    }

    setPagination({ current: pageNumber, pageSize: pagination.pageSize })

    return api.getResourceList(resourceUrl, token, options).then((res) => {
      setIsLoading(false)
      setCount(res && res.data && res.data.count)
      const newList = (res && res.data && res.data.rows) || []

      setList(newList)
    })
  }

  function filtersDidChange(newFilters) {
    setFilters(newFilters)
  }

  return [
    list,
    fetchList,
    isLoading,
    pagination,
    count,
    filtersDidChange,
    filters,
  ]
}
