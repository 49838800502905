import React from "react"
import { Route, Switch, Redirect, withRouter } from "react-router-dom"

import { useStateValue } from "../_shared/context/AppStateStore"
import NotFound from "../_shared/components/NotFound"

import LoggedApp from "./LoggedApp"
import AuthPage from "./AuthPage"
import StyledPasswordRecovery from "./AuthPage/PasswordRecovery"

//------------------------------------------------------------------------------

function RoutedApp(props) {
  const [{ user }] = useStateValue()

  const { match, location } = props

  if (user) {
    return (
      <Switch>
        <Redirect from={`${match.url}auth`} to={`${match.url}`} />
        <Route path={`${match.url}`} component={LoggedApp} />
        <Route component={NotFound} />
      </Switch>
    )
  } else {
    return (
      <Switch>
        {location.pathname !== "/auth" &&
          !location.pathname.startsWith("/reset-password") && (
            <Redirect from={`${match.url}`} to={`${match.url}auth`} />
          )}
        <Route exact path={`${match.url}auth/`} component={AuthPage} />
        <Route
          exact
          path={`${match.url}reset-password/`}
          component={StyledPasswordRecovery}
        />
        <Route
          exact
          path={`${match.url}reset-password/:token`}
          component={StyledPasswordRecovery}
        />
      </Switch>
    )
  }
}

// ----------------------------------------------------------------------------
// Router wrapper
// ----------------------------------------------------------------------------

const RoutedAppWithRouter = withRouter(RoutedApp)

// ----------------------------------------------------------------------------

export default RoutedAppWithRouter
