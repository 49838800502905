export default {
	INVALID_EMAIL: {
		key: "INVALID_EMAIL",
		message: "Email non valida.",
	},
	USER_FIRST_ACCESS: {
		key: "USER_FIRST_ACCESS",
	},

	USER_OK: {
		key: "USER_OK",
	},
}
