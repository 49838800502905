import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Popconfirm, Alert } from "antd"

import { TOKEN_STATUS } from "../../../../_shared/constants"
import TextInput from "../../../../_shared/components/FormItems/AppInput"
import AppButton from "../../../../_shared/components/AppButton"
import IconButton from "../../../../_shared/components/IconButton"
import AppSwitch from "../../../../_shared/components/FormItems/AppSwitch";

// ----------------------------------------------------------------------------

function AssociateBadgeForm(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { onSubmit, token, getFirstAccessPDF } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const [userId, setUserId] = useState("")
  const [authenticationId, setAuthenticationId] = useState("")
  const [badgeNumber, setBadgeNumber] = useState("")
  const [sendMail, setSendMail] = useState(true)

  const [name, setName] = useState("")
  const [surname, setSurname] = useState("")
  const [email, setEmail] = useState("")

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    if (token && token.id && token.appUser) {
      setUserId(token.userId)
      setAuthenticationId(token.authenticationId)
      setBadgeNumber(token.badgeNumber)
      setName(token.appUser.name)
      setSurname(token.appUser.surname)
      setEmail(token.appUser.email)
    } else {
      clearState()
    }
  }, [token])

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function isEditMode() {
    return token && token.id && token.appUser
  }

  function handleSubmit(event) {
    event.preventDefault()

    const body = {
      userId,
      authenticationId,
      badgeNumber,
      name,
      surname,
      email,
      sendMail
    }

    onSubmit && onSubmit(body)
  }

  function clearState() {
    setUserId("")
    setAuthenticationId("")
    setBadgeNumber("")
    setName("")
    setSurname("")
    setEmail("")
  }

  function handleDisableToken() {
    const body = {
      status: TOKEN_STATUS.DISABLED,
    }

    onSubmit && onSubmit(body)
  }

  function handleResetDevice() {
    const body = {
      //status: TOKEN_STATUS.DISABLED,
      firstAccessDate: null,
      deviceId: null,
      deviceInfo: null,
    }

    onSubmit && onSubmit(body)
  }

  function isBadgeDisabled() {
    return token && token.status === TOKEN_STATUS.DISABLED
  }

  function sendInvitationEmail() {
    const { sendInviationEmail } = props

    sendInviationEmail && sendInviationEmail()
  }

  function handleGetFirstAccessPDF() {
    getFirstAccessPDF && getFirstAccessPDF(token.id)
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  // early exit
  if (!token) {
    return null
  }

  return (
    <form className={props.className} onSubmit={handleSubmit}>
      <div className="form-content-container">
        <h1>
          {(!isEditMode() && "Associa badge") ||
            (badgeNumber && `Badge n. ${badgeNumber}`) ||
            `Modifica`}
        </h1>

        {isEditMode() && isBadgeDisabled() && (
          <Alert
            className="alert-message"
            message="Attenzione!"
            description="Il badge è stato disabilitato. Non è più possibile modificarne i valori."
            type="error"
          />
        )}

        <h4>{`Dati dell'utente`}</h4>
        <TextInput
          className="form-input"
          placeholder="Nome"
          value={name}
          onChange={setName}
          disabled={isBadgeDisabled()}
        />

        <TextInput
          className="form-input"
          placeholder="Cognome"
          value={surname}
          onChange={setSurname}
          disabled={isBadgeDisabled()}
        />

        <TextInput
          className="form-input"
          placeholder="Email"
          value={email}
          onChange={setEmail}
          disabled={isBadgeDisabled()}
        />

        <h4>Dati del badge</h4>

        <TextInput
          className="form-input"
          placeholder="ID Utente"
          value={userId}
          onChange={setUserId}
          disabled={isBadgeDisabled() || isEditMode()}
        />

        <TextInput
          className="form-input"
          placeholder="ID Autenticazione"
          value={authenticationId}
          onChange={setAuthenticationId}
          disabled={isBadgeDisabled() || isEditMode()}
        />

        <TextInput
          className="form-input"
          placeholder="Matricola"
          value={badgeNumber}
          onChange={setBadgeNumber}
          disabled={isBadgeDisabled()}
        />

        <AppSwitch
            className="form-switch"
            placeholder="Invia mail"
            value={sendMail}
            onChange={setSendMail}
        />

        <p className="first-access-code">
          <span>
            <strong>Codice primo accesso del token: </strong>
            {token.firstAccessCode}
          </span>
        </p>

        {isEditMode() && (
          <IconButton
            className="download-btn"
            type="button"
            style="ghosted"
            icon={require("./images/pdf-icn.svg")}
            onClick={handleGetFirstAccessPDF}
          >
            Scarica PDF
          </IconButton>
        )}
      </div>

      {!isBadgeDisabled() && (
        <div className="btn-container">
          {isEditMode() && !isBadgeDisabled() && (
            <Popconfirm
              title="Sicuro di voler disabilitare il badge?"
              onConfirm={handleDisableToken}
              onCancel={() => {}}
              okText="Si"
              cancelText="No"
            >
              <AppButton className="disable-btn" type="button" style="warning">
                Disabilita badge
              </AppButton>
            </Popconfirm>
          )}
          {isEditMode() && !isBadgeDisabled() && token.firstAccessDate && (
            <Popconfirm
              title="Sicuro di voler rimuovere il device autorizzato?"
              onConfirm={handleResetDevice}
              onCancel={() => {}}
              okText="Si"
              cancelText="No"
            >
              <AppButton className="disable-btn" type="button">
                Ripristina device
              </AppButton>
            </Popconfirm>
          )}

          {isEditMode() && !token.firstAccessDate && (
            <AppButton
              className="margined-btn"
              type="button"
              style="ghosted"
              onClick={sendInvitationEmail}
            >{`Rimanda email`}</AppButton>
          )}

          {!isBadgeDisabled() && (
            <AppButton className="form-btn">{`${
              isEditMode() ? "Modifica" : "Associa"
            }`}</AppButton>
          )}
        </div>
      )}
    </form>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

AssociateBadgeForm.propTypes = {
  className: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  token: PropTypes.object,
  sendInviationEmail: PropTypes.func,
  getFirstAccessPDF: PropTypes.func,
}

AssociateBadgeForm.defaultProps = {
  onSubmit: () => {},
  sendInviationEmail: () => {},
  getFirstAccessPDF: () => {},
}

// ----------------------------------------------------------------------------

const StyledAssociateBadgeForm = styled(AssociateBadgeForm)`
  & {
    display: flex;
    flex-direction: column;
    align-items: center;

    box-sizing: border-box;

    height: 100%;
    width: 100%;

    .form-content-container {
      flex: 1;

      display: flex;
      flex-direction: column;
      align-items: center;

      padding: 25px;

      > h1 {
        margin-bottom: 25px;
        font-weight: bold;
      }

      .alert-message {
        margin-bottom: 25px;
      }

      h4 {
        margin-bottom: 10px;

        border-bottom: 1px solid ${(props) => props.theme.UITheme.textLight};
      }
      .form-input {
        margin-bottom: 12px;
      }

      .first-access-code {
        margin: 10px 0;
      }

      .download-btn {
        margin-top: 20px;
      }
    }

    .btn-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      padding: 20px 10px;

      width: 100%;

      background-color: ${(props) => props.theme.UITheme.dividerColor};

      box-sizing: border-box;

      width: 100%;

      .margined-btn {
        margin-right: 10px;
      }

      .disable-btn {
        margin-right: 10px;
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledAssociateBadgeForm
