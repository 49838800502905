import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

//------------------------------------------------------------------------------

function AppButton(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { children, onClick, type } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function getThemeClassName() {
    const { style } = props

    switch (style) {
      case "warning": {
        return "warning"
      }

      case "ghosted": {
        return "ghosted"
      }

      default: {
        return ""
      }
    }
  }
  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <button
      className={`${props.className} ${getThemeClassName()}`}
      type={type}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

AppButton.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
  style: PropTypes.string,
}

AppButton.defaultProps = {
  onClick: () => {},
  type: "submit",
  style: "default",
}

// ----------------------------------------------------------------------------

const StyledAppButton = styled(AppButton)`
  & {
    padding: 0 24px;
    max-width: 250px;
    height: 36px;
    border: 1px solid ${props => props.theme.UITheme.accentColor};
    border-radius: 4px;
    background-color: ${props => props.theme.UITheme.accentColor};

    color: ${props => props.theme.UITheme.white} !important;
    cursor: pointer;

    outline: none;
    transition: all 0.2s ease-in-out;

    :hover {
      background-color: ${props => `${props.theme.UITheme.accentColor}E6`};
    }
  }

  &.ghosted {
    border: 2px solid ${props => props.theme.UITheme.accentColor};
    background-color: transparent;
    color: ${props => props.theme.UITheme.accentColor} !important;

    :hover {
    }
  }

  &.warning {
    border: 2px solid ${props => props.theme.UITheme.warningDarkColor};
    border-radius: 4px;
    background-color: transparent;
    color: ${props => props.theme.UITheme.warningDarkColor} !important;

    :hover {
      border-color: ${props => props.theme.UITheme.warningLightColor};
      color: ${props => props.theme.UITheme.warningLightColor} !important;
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledAppButton
