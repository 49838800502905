import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Table } from "antd"

//------------------------------------------------------------------------------

function AppTable(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className, ...tableProps } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return <Table className={className} {...tableProps} />
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

AppTable.propTypes = {
  className: PropTypes.string.isRequired,
}

AppTable.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledAppTable = styled(AppTable)`
  & {
    border-radius: 6px 6px 0 0;

    background-color: ${(props) => props.theme.UITheme.white};

    .ant-table-body {
      table {
        border-radius: 6px 6px 0 0;

        background-color: ${(props) => props.theme.UITheme.white};

        th,
        td {
          border-bottom-color: ${(props) =>
            props.theme.UITheme.backgroundColor};
        }
      }
    }

    .ant-table-thead > tr > th {
      background-color: transparent;
      font-weight: 600;
    }

    .ant-table-row {
      cursor: pointer;

      .device-id {
        font-size: 10px;
      }
    }

    .ant-pagination-item.ant-pagination-item-1.ant-pagination-item-active {
      background-color: transparent;
      border-color: ${(props) => props.theme.UITheme.primaryColor};

      a {
        color: ${(props) => props.theme.UITheme.primaryColor};
      }
    }

    .ant-table-placeholder {
      background-color: transparent;
    }

    .anticon.anticon-search.ant-table-filter-icon.ant-table-filter-selected.filter-icon.ant-dropdown-trigger {
      color: #0081ff !important;

      * {
        color: #0081ff !important;
      }
    }

    .anticon.anticon-calendar.ant-table-filter-icon.ant-table-filter-selected.filter-icon.ant-dropdown-trigger {
      color: #0081ff !important;

      * {
        color: #0081ff !important;
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledAppTable
