import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

import "./input.css"

//------------------------------------------------------------------------------

function UnitOfMeasurementInput(props) {
  const {
    className,
    placeholder,
    hiddenText,
    value,
    validationMessage,
    disabled,
    required,
    unitOfMeasurement,
  } = props

  function onChange(event) {
    const { onChange } = props

    onChange(event.target.value)
  }

  function onBlur(event) {
    const { onBlur } = props

    onBlur(event.target.value)
  }

  return (
    <div className={`${className} input-container`}>
      <input
        type={(hiddenText && "password") || "text"}
        value={value !== undefined ? value : ""}
        className="tm4-input"
        onChange={onChange}
        onBlur={onBlur}
        required={required}
        disabled={disabled}
      />
      <label id="inputLabel" className={`input-label`}>
        {placeholder}
      </label>

      {unitOfMeasurement && (
        <span className="unit-of-measurement">{unitOfMeasurement}</span>
      )}

      {validationMessage && (
        <span className="validation-error-message">{validationMessage}</span>
      )}
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

UnitOfMeasurementInput.propTypes = {
  className: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  hiddenText: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  validationMessage: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  unitOfMeasurement: PropTypes.string,
}

UnitOfMeasurementInput.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
  validationMessage: null,
  disabled: false,
  unitOfMeasurement: "",
}

// ----------------------------------------------------------------------------

const StyledUnitOfMeasurementInput = styled(UnitOfMeasurementInput)`
  & {
    position: relative;

    ${props =>
      props.disabled &&
      css`
        cursor: not-allowed;
      `}

    .tm4-input {
      border-color: ${props =>
        (props.validationMessage && "rgb(255,59,48)") || "auto"} !important;

      ${props =>
        props.disabled &&
        css`
          background-color: #f3f3f3 !important;
          cursor: not-allowed;
        `}
    }

    .input-label {
      user-select: none;
      ${props =>
        props.disabled &&
        css`
          z-index: 1;
          color: #d6d6d6;
          cursor: not-allowed;
        `}
    }

    .unit-of-measurement {
      position: absolute;

      right: 10px;
      top: 50%;

      transform: translateY(-50%);

      font-weight: 600;
    }

    .validation-error-message {
      position: absolute;
      top: 50%;
      left: 102%;
      width: 50%;
      transform: translateY(-50%);
      font-size: 10px;
      font-weight: 600;
      color: rgb(255, 59, 48);
    }

    /* MOBILE */
    @media (max-width: ${props => props.theme.screenSizes.mobile}px) {
      .validation-error-message {
        position: absolute;
        top: 101%;
        left: 10px;
        width: 100%;
        transform: unset;
        line-height: 1;
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledUnitOfMeasurementInput
