import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Route, NavLink as Link, Switch, Redirect } from "react-router-dom"
import { Layout, Menu, Icon } from "antd"

import ROLES from "../../_shared/constants/roles.js"
import { useStateValue } from "../../_shared/context/AppStateStore"

import Header from "./Header"
import Users from "./Users"
import Tokens from "./Tokens"

// ----------------------------------------------------------------------------

const { Content, Footer, Sider } = Layout

// ----------------------------------------------------------------------------

function LoggedApp(props) {
  const [{ user }] = useStateValue()

  const { match, location } = props

  const renderRoutedContent = () => {
    return (
      <Switch>
        {user.role === ROLES.ADMIN && location.pathname === "/" && (
          <Redirect from={`${match.url}`} to={`/tokens`} />
        )}
        {user.role === ROLES.SUPER_ADMIN && location.pathname === "/" && (
          <Redirect from={`${match.url}`} to={`${match.url}users`} />
        )}

        <Route path="/users" exact component={Users} />
        <Route path="/tokens" exact component={Tokens} />
      </Switch>
    )
  }

  return (
    <div className={props.className}>
      <Layout>
        <Sider
          className="sidebar"
          breakpoint="lg"
          collapsedWidth="0"
          // onBreakpoint={broken => {
          //   console.log(broken);
          // }}
          // onCollapse={(collapsed, type) => {
          //   console.log(collapsed, type);
          // }}
        >
          <div className="logo" />
          <Menu theme="dark" mode="inline" defaultSelectedKeys={["projects"]}>
              {user.role === ROLES.SUPER_ADMIN && (
                  <Menu.Item key="users">
                      <Link to="/users" activeClassName="current-route">
                          <Icon type="user" />
                          <span className="menu-text">Utenti</span>
                      </Link>
                  </Menu.Item>
              )}

            {user.role === ROLES.ADMIN && (
              <Menu.Item key="users">
                <Link to="/tokens" activeClassName="current-route">
                  <Icon type="user" />
                  <span className="menu-text">Badges</span>
                </Link>
              </Menu.Item>
            )}
          </Menu>
        </Sider>
        <Layout>
          <Header />
          <div className="main-container">
            <Content>{renderRoutedContent()}</Content>
            <Footer style={{ textAlign: "center" }}>
              Sintesi Srl © {new Date().getFullYear()}
            </Footer>
          </div>
        </Layout>
      </Layout>
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

LoggedApp.propTypes = {
  className: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired, // from router
  location: PropTypes.object.isRequired, // from router
}

LoggedApp.defaultProps = {}

// ----------------------------------------------------------------------------
// Styled wrapper
// ----------------------------------------------------------------------------

const StyledLoggedApp = styled(LoggedApp)`
  /* GENERAL APP STYLES */

  * {
    color: ${props => props.theme.UITheme.textColor};
  }

  /* ------------------ */
  & {
    min-height: 100vh;
    max-height: 100vh;
    min-width: 100vw;
    overflow-x: hidden;
    background-color: ${props => props.theme.UITheme.backgroundColor};

    .ant-layout {
      min-height: 100vh;
      max-height: 100vh;

      background-color: ${props => props.theme.UITheme.backgroundColor};

      aside {
        background: #196da9;
        background: -webkit-linear-gradient(
          ${props => props.theme.UITheme.backgroundGradient}
        );

        background: linear-gradient(
          ${props => props.theme.UITheme.backgroundGradient}
        );

        .logo {
          box-sizing: border-box;
          padding: 8px;
          min-height: 64px;

          background-size: contain;
          background-repeat: no-repeat;
          background-origin: content-box;
        }

        .ant-menu {
          margin-top: 50px;
          background-color: transparent;

          .anticon {
            color: ${props => props.theme.UITheme.backgroundColor};

            * {
              color: ${props => props.theme.UITheme.backgroundColor};
            }
          }

          .menu-text {
            color: ${props => props.theme.UITheme.backgroundColor};
            font-weight: normal;
          }

          .ant-menu-item {
            padding-right: 0;
          }

          .ant-menu-item:active {
            background: none;
          }

          .ant-menu-item-selected {
            background-color: transparent;
          }

          .current-route {
            padding-left: 22px;
            background: rgba(255, 255, 255, 0.13);
            border-top-left-radius: 22px;
            border-bottom-left-radius: 22px;
          }
        }
      }

      .main-container {
        display: flex;
        flex-direction: column;

        height: 100%;

        overflow-y: auto;

        main {
          flex: 1;
          padding: 24px;
          height: auto;
          min-height: auto;
        }

        footer {
          padding: 10px;
          background-color: transparent;
          color: #7f8fa4;
          font-size: 10px;
        }
      }
    }
  }
`
export default StyledLoggedApp
