/* global process */
import URLJoin from "url-join"

// ----------------------------------------------------------------------------

const DEBUG_API = process.env.NODE_ENV === "development"

const DEFAULT_API_PORT = "3001"
const BASE_URL = getBaseURL()
const ACCESS_TOKEN = "ccYvkFIxKvjulFJuP6mxMOrSej5tHJHi"

// ----------------------------------------------------------------------------

function getBaseURL() {
  if (process.env.NODE_ENV === "development") {
    return `http://127.0.0.1:8888`
  } else {
    return "https://backend.beeid.app"
  }
}

// ----------------------------------------------------------------------------

export class APINotOkError extends Error {
  constructor(statusCode, serverResponse) {
    const message =
      (serverResponse && serverResponse.message) ||
      `HTTP status code: ${statusCode}`
    super(message)
    this.name = "APINotOkError"
    this.statusCode = statusCode
    this.serverMessage = serverResponse && serverResponse.state
    this.errorKey = serverResponse && serverResponse.errorKey
  }
}

// ----------------------------------------------------------------------------
// Auth
// ----------------------------------------------------------------------------

export function signUp({ ...userBody }) {
  const headers = {}
  const body = {
    ...userBody,
    access_token: ACCESS_TOKEN,
  }

  return executePOSTRequest(URLJoin(BASE_URL, "/users"), null, body, headers)
}

export function signIn(email, password) {
  const base64 = require("js-base64").Base64
  var encoded = base64.encode(`${email}:${password}`)
  const headers = {
    Authorization: `Basic ${encoded}`,
  }

  const body = { access_token: ACCESS_TOKEN, adminLogin: true }

  return executePOSTRequest(URLJoin(BASE_URL, "/auth"), null, body, headers)
}

export async function checkUserEmail(email, deviceId) {
  const headers = {}

  const body = {
    access_token: ACCESS_TOKEN,
    email,
    skipPassword: true,
  }

  return executePOSTRequest(URLJoin(BASE_URL, "/auth"), null, body, headers)
}

// ----------------------------------------------------------------------------

export function getUser(token) {
  let url = "users/me"
  return executeGETRequest(url, token)
}

// ----------------------------------------------------------------------------
// Users
// ----------------------------------------------------------------------------

export function createAdminUser(body) {
  return signUp(body)
}

export function updateUser({ ...body }, token) {
  const url = `/users/${body.id}`
  const userBody = {
    ...body,
  }

  const headers = {}

  return executePUTRequest(url, token, userBody, headers)
}

export async function createAdminPassword(email, newPassword) {
  const headers = {}
  const body = {
    access_token: ACCESS_TOKEN,
    email,
    password: newPassword,
  }

  return executePOSTRequest(
    URLJoin(BASE_URL, "/users/email/password"),
    null,
    body,
    headers
  )
}

export async function createNewAdminTokens(userId, body, token) {
  const url = `/users/${userId}/tokens`

  const headers = {}

  return executePOSTRequest(URLJoin(BASE_URL, url), token, body, headers)
}

export function adminNewTokenRequest(userId, body, token) {
  const url = `/users/${userId}/tokens/request`

  const headers = {}

  return executePOSTRequest(URLJoin(BASE_URL, url), token, body, headers)
}

// ----------------------------------------------------------------------------
// Tokens
// ----------------------------------------------------------------------------

export function updateToken(tokenId, body, token) {
  const url = `/tokens/${tokenId}`

  const headers = {}

  return executePUTRequest(url, token, body, headers)
}

export function associateUserToken(tokenId, body, token, skipSendMail) {

  const url = `/tokens/${tokenId}/app-user/associate?skipSendMail=${skipSendMail}`

  const headers = {}

  return executePOSTRequest(url, token, body, headers)
}

export function sendAppUserInviationEmail(tokenId, loggedUserToken) {
  const url = `/tokens/${tokenId}/app-user/invite`

  const headers = {}

  const body = {}

  return executePOSTRequest(url, loggedUserToken, body, headers)
}

export async function getFirstAccessPDF(tokenId, token) {
  const url = `${BASE_URL}/tokens/${tokenId}/first-access/pdf`

  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", "file.pdf")
  document.body.appendChild(link)
  link.click()
  link.remove()
}

// ----------------------------------------------------------------------------
// Reset password
// ----------------------------------------------------------------------------

export function forgotPassword(email) {
  const url = BASE_URL + "/password-resets"

  const { protocol, hostname } = window.location
  const linkURL = `${protocol}//${hostname}`

  const headers = {}
  const body = {
    email,
    access_token: ACCESS_TOKEN,
    link: `${linkURL}/reset-password`,
  }

  return executePOSTRequest(url, null, body, headers)
}

export function resetPassword(token, newPassword) {
  const url = BASE_URL + `/password-resets/${token}`

  const headers = {}
  const body = {
    password: newPassword,
  }

  return executePUTRequest(url, null, body, headers)
}

// ----------------------------------------------------------------------------
// REST Resources
// ----------------------------------------------------------------------------

export function getResourceList(resourceURI, token, options) {
  const { pageNumber, pageSize = 10, sort, filters } = options
  const url = URLJoin(BASE_URL, resourceURI)

  const filterParams = {}
  for (let filterKey in filters) {
    if (filters[filterKey].length) {
      if (Array.isArray(filters[filterKey])) {
        filterParams[filterKey] = filters[filterKey].join(",")
      } else {
        filterParams[filterKey] = filters[filterKey]
      }
    }
  }

  const params = {
    ...filterParams,
    limit: pageSize,
  }

  if (pageNumber) {
    params.page = pageNumber
  }

  if (sort) {
    const sortOperator = sort.order === "descend" ? "-" : "+"
    params.sort = `${sortOperator}${sort.key}`
  }

  const urlWithQuery = urlWithQueryParams(url, params)
  return executeGETRequest(urlWithQuery, token, { isList: true })
}

export function getResource(resourceURI, token) {
  const url = URLJoin(BASE_URL, resourceURI)
  return executeGETRequest(url, token, { isList: false })
}

export function createResource(resourceURI, token, resource) {
  const url = URLJoin(BASE_URL, resourceURI)
  return executePOSTRequest(url, token, resource)
}

export function updateResource(resourceURI, token, resourceID, resource) {
  const url = URLJoin(BASE_URL, resourceURI, resourceID)

  return executePUTRequest(url, token, resource)
}

export function deleteResource(resourceURI, token, resourceID) {
  const url = URLJoin(BASE_URL, resourceURI, resourceID)
  return executeDELETERequest(url, token)
}

// ----------------------------------------------------------------------------
// Private helpers
// ----------------------------------------------------------------------------

function executeGETRequest(url, token, options) {
  const headers = {
    Authorization: `Bearer ${token}`,
  }
  return executeRequest(url, "get", null, headers, options)
}

function executePOSTRequest(url, token, body, headers) {
  const newHeaders = headers || {}
  if (token) {
    newHeaders["Authorization"] = `Bearer ${token}`
  }
  return executeRequest(url, "post", body, newHeaders)
}

function executePUTRequest(url, token, body, headers) {
  const newHeaders = headers || {}
  if (token) {
    newHeaders["Authorization"] = `Bearer ${token}`
  }
  return executeRequest(url, "put", body, newHeaders)
}

function executeDELETERequest(url, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  }
  return executeRequest(url, "delete", null, headers)
}

// ----------------------------------------------------------------------------

function executeRequest(givenURL, method, givenBody, givenHeaders, options) {
  let url = normalizeURL(givenURL)
  const headers = {
    ...givenHeaders,
    Accept: "application/json",
    "Content-Type": "application/json",
  }
  let body
  if (givenBody) {
    body = JSON.stringify(givenBody)
    if (DEBUG_API) {
      // eslint-disable-next-line
      console.debug(`[API/REQUEST/BODY]`, url, givenBody)
    }
  }
  const requestData = {
    method,
    headers,
    body,
  }
  if (DEBUG_API) {
    // eslint-disable-next-line
    console.debug(`[API/REQUEST]`, url, requestData)
  }

  return fetch(url, requestData).then((response) => {
    if (DEBUG_API) {
      // eslint-disable-next-line
      console.debug(`[API/RESPONSE]`, url, response)
    }

    return response.text().then(function (text) {
      let responseBody
      try {
        responseBody = JSON.parse(text)
      } catch (e) {
        responseBody = text
      }

      if (DEBUG_API) {
        // eslint-disable-next-line
        console.debug(`[API/RESPONSE/BODY]`, responseBody)
      }

      if (response.ok) {
        let headers = response.headers
        if (options && options.isList) {
          return {
            data: responseBody,
          }
        } else {
          return responseBody
        }
      } else {
        throw new APINotOkError(response.status, responseBody)
      }
    })
  })
}

// -------------------------------------

function normalizeURL(givenURL) {
  let isPathAbsolute =
    givenURL.indexOf("http://") === 0 || givenURL.indexOf("https://") === 0
  if (isPathAbsolute) {
    return givenURL
  } else {
    return URLJoin(getBaseURL(), givenURL.replace(/^\.\//, ""))
  }
}

function urlWithQueryParams(url, params) {
  const urlParams = Object.entries(params)
    .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
    .join("&")
  return `${url}?${urlParams}`
}

// ----------------------------------------------------------------------------
