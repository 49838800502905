import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

//------------------------------------------------------------------------------

function AppButton(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { children, onClick, type, icon } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------
  //
  //   function getThemeClassName() {
  //     const { style } = props
  //
  //     switch (style) {
  //       case "warning": {
  //         return "warning"
  //       }
  //
  //       case "ghosted": {
  //         return "ghosted"
  //       }
  //
  //       default: {
  //         return ""
  //       }
  //     }
  //   }
  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <button className={`${props.className}`} type={type} onClick={onClick}>
      {icon && <img src={icon} />}
      {children}
    </button>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

AppButton.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
  style: PropTypes.string,
  icon: PropTypes.any,
}

AppButton.defaultProps = {
  onClick: () => {},
  type: "submit",
  style: "default",
}

// ----------------------------------------------------------------------------

const StyledAppButton = styled(AppButton)`
  & {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 0 24px;
    max-width: 250px;
    height: 36px;

    border: 1px solid ${props => props.theme.UITheme.lightGrey};
    border-radius: 4px;
    background-color: ${props => props.theme.UITheme.darkBackgroundColor};
    color: ${props => props.theme.UITheme.accentColor} !important;
    cursor: pointer;

    outline: none;
    transition: all 0.2s ease-in-out;

    > img {
      margin-right: 10px;
    }

    :hover {
      border: 1px solid ${props => props.theme.UITheme.accentColor};

      background-color: ${props => props.theme.UITheme.accentColor};
      color: ${props => props.theme.UITheme.white} !important;
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledAppButton
