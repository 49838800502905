import React, { createContext, useContext, useReducer, useEffect } from "react"

// ----------------------------------------------------------------------------

export const StateContext = createContext()

// ----------------------------------------------------------------------------

export const AppStateStore = ({
  reducer,
  initialState,
  storeKey,
  children,
}) => {
  const reducerValue = useReducer(
    reducer,
    JSON.parse(localStorage.getItem(`dsc.app-state-store.${storeKey}`)) ||
      initialState
  )

  useEffect(() => {
    const [newState] = reducerValue

    localStorage.setItem(
      `dsc.app-state-store.${storeKey}`,
      JSON.stringify(newState)
    )

    return function cleanup() {
      localStorage.setItem(
        `dsc.app-state-store.${storeKey}`,
        JSON.stringify(newState)
      )
    }

    function cleanStore() {
      localStorage.removeItem(`dsc.app-state-store.${storeKey}`)
    }

    // eslint-disable-next-line
  }, [reducerValue])

  return (
    <StateContext.Provider value={reducerValue}>
      {children}
    </StateContext.Provider>
  )
}

// ----------------------------------------------------------------------------
// export read context hook
// ----------------------------------------------------------------------------

export const useStateValue = () => useContext(StateContext)

// ----------------------------------------------------------------------------
