import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { message } from "antd"
import { useHistory } from "react-router"

import * as api from "../../../../api"

import { BACKEND_RESPONSES_MESSAGES } from "../../../_shared/constants"
import AppInput from "../../../_shared/components/FormItems/AppInput"

//------------------------------------------------------------------------------

function PasswordRecovery(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const token =
    props && props.match && props.match.params && props.match.params.token

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const history = useHistory()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [recoverySuccess, setRecoverySuccess] = useState(false)

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function handleRecovery() {
    api
      .forgotPassword(email)
      .then((res) => {
        setRecoverySuccess(true)
      })
      .catch((error) => {
        if (error && error.statusCode === 401) {
          message.error("Le credenziali non sono corrette.")
        } else {
          const defaultMessage =
            "Non è stato possibile completare il login. Riprovare più tardi."

          const messageText = (error && error.serverMessage) || defaultMessage

          message.error(messageText)
        }
      })
  }

  function checkUserEmailStatus(event) {
    event.preventDefault()

    api
      .checkUserEmail(email)
      .then((res) => {
        if (
          res &&
          res.state &&
          BACKEND_RESPONSES_MESSAGES.USER_OK &&
          res.state === BACKEND_RESPONSES_MESSAGES.USER_OK.key
        ) {
          return handleRecovery()
        }
      })
      .catch((err) => {
        if (err) {
          const errorMessage =
            (err.serverMessage &&
              BACKEND_RESPONSES_MESSAGES[err.serverMessage] &&
              BACKEND_RESPONSES_MESSAGES[err.serverMessage].message) ||
            "Non è stato possibile completare l'operazione."

          message.error(errorMessage)
        }
      })
  }

  function resetAdminPassword(event) {
    event.preventDefault()

    if (token && password !== confirmPassword) {
      return message.error("Le password devono essere uguali.")
    }

    api
      .resetPassword(token, password)
      .then((res) => {
        history.push("/auth")
      })
      .catch((err) =>
        message.error(
          "Non è stato possibile configurare la password. Conttatare gli amministratori."
        )
      )
  }

  function getSubmitFunctionBasedOnState() {
    if (token) {
      return resetAdminPassword
    } else {
      return checkUserEmailStatus
    }
  }
  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={props.className}>
      <div className="auth-card">
        <div className="message-container">
          <h1>{`Benvenuto nell'admin di BEEID!`}</h1>

          <h4>Inserisci le credenziali per gestire i tuoi accessi.</h4>
        </div>

        <div className="form-container">
          <form onSubmit={getSubmitFunctionBasedOnState()}>
            <h1>Recupero Password</h1>

            {!token && (
              <h4>{` Inserisci l'email dell'account che vuoi recuperare.`}</h4>
            )}
            {token && <h4>{` Inserisci una nuova password.`}</h4>}

            {!token && !recoverySuccess && (
              <AppInput
                className="auth-input"
                placeholder="Email"
                value={email}
                onChange={setEmail}
                autocomplete={false}
              />
            )}

            {!token && recoverySuccess && (
              <h4>{` Una email di recuper password è stata inviata al tuo indirizzo. Segui le istruzioni in essa per recuperare la password.`}</h4>
            )}

            {token && (
              <div className="create-password-container">
                <AppInput
                  className="auth-input"
                  placeholder="Password"
                  value={password}
                  onChange={setPassword}
                  hiddenText
                  autocomplete={false}
                />

                <AppInput
                  className="auth-input"
                  placeholder="Conferma password"
                  value={confirmPassword}
                  onChange={setConfirmPassword}
                  hiddenText
                  autocomplete={false}
                />
              </div>
            )}

            {!recoverySuccess && (
              <button className="sign-in-btn">
                {token ? "Reimposta Password" : "Recupera password"}
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

PasswordRecovery.propTypes = {
  className: PropTypes.string.isRequired,
  match: PropTypes.object,
}

PasswordRecovery.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledPasswordRecovery = styled(PasswordRecovery)`
  & {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 100vh;

    min-width: 100vw;

    background-color: #161d26;

    background-image: url(${`${require("../images/auth-background-dark.png")}`});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    .auth-card {
      position: relative;

      display: flex;
      flex-direction: row;

      width: 80vw;
      height: 75vh;

      max-width: 1128px;
      max-height: 700px;

      background-color: ${(props) => props.theme.UITheme.white};

      border-radius: 8px;

      overflow: hidden;

      .form-container {
        flex: 6;

        display: flex;
        flex-direction: column;
        align-items: center;

        padding-top: 120px;

        > form {
          display: flex;
          flex-direction: column;
          align-items: center;

          width: 85%;

          h1 {
            color: ${(props) => props.theme.UITheme.accentTextColor};
            font-weight: 600;
          }

          h4 {
            margin-bottom: 25px;
            max-width: 50%;
            color: ${(props) => props.theme.UITheme.accentLighterColor};
            text-align: center;
            font-weight: 600;
          }

          .create-password-container {
            width: 250px;
          }

          .auth-input {
            margin-bottom: 25px;
          }

          > .sign-in-btn {
            width: 100%;
            max-width: 250px;
            height: 36px;
            border: 1px solid #313d4f;
            border-radius: 4px;
            background-color: #273142;
            color: #ffffff;
            cursor: pointer;

            outline: none;
            transition: all 0.2s ease-in-out;

            :hover {
              border: 1px solid #ffffff;
            }
          }
          .password-recovery {
            margin-top: 30px;
            width: 100%;
            max-width: 250px;
            text-align: right;
            color: ${(props) => props.theme.UITheme.accentTextColor};
          }
        }
      }

      .message-container {
        flex: 4;

        display: flex;
        flex-direction: column;
        align-items: center;

        padding-top: 120px;

        background-image: linear-gradient(134deg, #09b66d 0%, #22cce2 100%);

        h1 {
          width: 80%;
          color: ${(props) => props.theme.UITheme.white};
          font-weight: 600;
          text-align: center;
        }

        h4 {
          color: ${(props) => props.theme.UITheme.white};
          text-align: center;
          font-weight: 600;
        }
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledPasswordRecovery
