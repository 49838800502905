import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

//------------------------------------------------------------------------------

function Drawer(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { open, children, onCancel } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  if (open) {
    document.body.style.overflow = "hidden"
  } else {
    document.body.style.overflow = ""
  }

  return (
    <div className={`${props.className}${open ? " open" : ""}`}>
      <div className="mask" />

      <div className="drawer">
        <div className="close-icn" onClick={onCancel} />

        {children}
      </div>
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

Drawer.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.func,
  children: PropTypes.any,
  onCancel: PropTypes.func,
}

Drawer.defaultProps = {
  open: () => {},
  onCancel: () => {},
}

// ----------------------------------------------------------------------------

const StyledDrawer = styled(Drawer)`
  & {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;

    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    overflow-x: hidden;

    z-index: 998;

    .drawer {
      position: fixed;
      top: 0;
      right: 0;

      min-width: 500px;
      width: 35vw;
      height: 100vh;
      background-color: #ffffff;
      z-index: 999;

      .close-icn {
        position: absolute;
        top: 24px;
        right: 24px;

        width: 16px;
        height: 16px;
        background-image: url(${require("./images/close-icn.svg")});
        background-size: 16px 16px;
        background-repeat: no-repeat;
        background-origin: content-box;

        cursor: pointer;

        transition: all 0.2s ease;
      }
    }

    .mask {
      position: fixed;
      opacity: 0;
      transition: opacity 0.1s ease 0.3s;
      z-index: 998;
    }
  }

  &.open {
    transform: translateX(0);

    .mask {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: scale(1);
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.5);
    }

    /* MOBILE */

    @media (max-width: ${props => props.theme.screenSizes.mobile}px) {
      .drawer {
        min-width: 100vw;
        width: 100vw;
        max-width: 100vw;
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledDrawer
