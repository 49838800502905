import React, { useRef } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { Select } from "antd"

//------------------------------------------------------------------------------

function AppSelect(props) {
  let containerRef = useRef(null)

  // -------------------------------------

  const {
    className,
    placeholder,
    children,
    value,
    disabled,
    validationMessage,
    onBlur,
    loading,
    ...restProps
  } = props

  function onChange(value) {
    const { onChange } = props

    onChange(value)
  }

  const validValueClass =
    value !== undefined && value !== null && value !== "" ? "valid-value" : ""

  return (
    <div className={`${className} select-input-container`} ref={containerRef}>
      <Select
        value={value || null}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        getPopupContainer={() => {
          return containerRef && containerRef.current
        }}
        loading={loading}
        {...restProps}
      >
        {children}
      </Select>
      <label id="inputLabel" className={`input-label ${validValueClass}`}>
        {placeholder}
      </label>

      {validationMessage && (
        <span className="validation-error-message">{validationMessage}</span>
      )}
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

AppSelect.propTypes = {
  className: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  children: PropTypes.array,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  validationMessage: PropTypes.string,
  onSearch: PropTypes.func,
  loading: PropTypes.bool,
  smallText: PropTypes.any,
}

AppSelect.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
  disabled: false,
  validationMessage: null,
  loading: false,
  onSearch: null,
}

// ----------------------------------------------------------------------------

const StyledAppSelect = styled(AppSelect)`
  & {
    position: relative;
    max-width: 326px;

    * {
      box-shadow: none;
      outline: none;
    }

    .validation-error-message {
      position: absolute;
      top: 50%;
      left: 102%;
      width: 50%;
      transform: translateY(-50%);
      font-size: 10px;
      font-weight: 600;
      color: rgb(255, 59, 48);
    }

    .select-input-container {
      position: relative;
      display: flex;
      flex-direction: column;
      border-radius: 6px;
      min-height: 42px;
      height: 42px;
      max-height: 42px;
      font-size: 14px;
      max-width: 326px;
      width: 100%;
      background-color: transparent;
      outline: none;
    }

    .input-label {
      z-index: 1;
      position: absolute;
      top: 8.5px;
      left: 15px;
      font-size: 14px;
      color: #d6d6d6;
      transition: 0.3s ease all;
      -moz-transition: 0.3s ease all;
      -webkit-transition: 0.3s ease all;

      ${props =>
        props.disabled &&
        css`
          z-index: 2;
          color: #d6d6d6;
        `}
    }

    .small {
      font-size: 11px;
    }

    .ant-select {
      position: relative;
      max-width: 100%;
      width: 100%;
      max-width: 326px;

      .ant-select-selection {
        flex: 1;
        z-index: 2;
        border: 1px solid
          ${props =>
            (props.validationMessage && "rgb(255,59,48)") ||
            props.theme.UITheme.accentColor};
        border-radius: 6px;
        padding: 0 15px 0 15px;
        min-height: 42px;
        height: 42px;
        max-height: 42px;
        font-family: -apple-system, "SF Pro Text", "SF Pro Icons",
          "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
        background-color: transparent !important;

        font-size: 14px;
        color: #494949;
        outline: none;

        .ant-select-selection__rendered {
          margin-left: 0;
          height: 100%;
        }

        .ant-select-selection-selected-value {
          padding-top: ${props => (props.placelholder && "10px") || 0};
          line-height: ${props => (props.placelholder && "auto") || "40px"};
        }

        ${props =>
          props.disabled &&
          css`
            background-color: #f3f3f3 !important;
            cursor: not-allowed;
          `}

        :focus {
          outline: none;
          box-shadow: none;
        }
      }
    }

    .ant-select-focused {
      .ant-select-selection {
        outline-style: none;
        border-color: ${props => props.theme.UITheme.accentTextColor};
      }

      input {
        padding-top: 8px;
      }
    }

    .select-input-container > .ant-select-focused ~ .input-label,
    .ant-select-focused ~ .input-label {
      position: absolute;
      top: 2px;
      color: ${props => props.theme.UITheme.accentTextColor};
      font-size: 10px;
    }

    .valid-value.input-label {
      position: absolute;
      top: 2px;
      color: ${props => props.theme.UITheme.accentTextColor};
      font-size: 10px;
    }

    /* MOBILE */
    @media (max-width: ${props => props.theme.screenSizes.mobile}px) {
      .validation-error-message {
        position: absolute;
        top: 101%;
        left: 10px;
        width: 100%;
        transform: unset;
        line-height: 1;
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledAppSelect
