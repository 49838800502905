import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import TextInput from "../FormItems/AppInput"
import AppButton from "../AppButton"

//------------------------------------------------------------------------------

function NewTokensForm(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { onSubmit, buttonText, visible, inputPlaceholder } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const [tokenNumber, setTokenNumber] = useState(0)

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    if (!visible) {
      setTokenNumber("")
    }
  }, [visible])

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function handleSubmit(event) {
    event.preventDefault()
    event.stopPropagation()

    onSubmit && onSubmit(Number(tokenNumber))
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <form className={props.className} onSubmit={handleSubmit}>
      <TextInput
        className="form-input"
        placeholder={inputPlaceholder}
        value={tokenNumber}
        onChange={setTokenNumber}
      />

      <AppButton className="new-tokens-btn">{buttonText}</AppButton>
    </form>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

NewTokensForm.propTypes = {
  className: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  buttonText: PropTypes.string,
  visible: PropTypes.bool,
  inputPlaceholder: PropTypes.string,
}

NewTokensForm.defaultProps = {
  onSubmit: () => {},
  buttonText: "Crea",
  inputPlaceholder: "Numero badge",
}

// ----------------------------------------------------------------------------

const StyledNewTokensForm = styled(NewTokensForm)`
  & {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .form-input {
      margin-right: 5px;
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledNewTokensForm
