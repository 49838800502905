import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { Switch } from "antd"

//------------------------------------------------------------------------------

function AppSwitch(props) {
  const { className, placeholder, value, ...restProps } = props

  function onChange(value) {
    const { onChange } = props

    onChange(value)
  }

  return (
    <div className={className}>
      <label className={`switch-label`}>{placeholder}</label>

      <Switch checked={value} onChange={onChange} {...restProps} />
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

AppSwitch.propTypes = {
  className: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  validationMessage: PropTypes.string,
  smallText: PropTypes.any,
}

AppSwitch.defaultProps = {
  onChange: () => {},
  validationMessage: null,
}

// ----------------------------------------------------------------------------

const StyledAppSwitch = styled(AppSwitch)`
  & {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    border-radius: 6px;

    font-size: 17px;
    max-width: 250px;
    width: 100%;
    background-color: transparent;

    .switch-label {
      flex: 1;
      margin-right: 10px;
      position: relative;
      z-index: 1;
      font-size: 14px;
      color: ${props => props.theme.UITheme.accentTextColor};
      transition: 0.3s ease all;
      -moz-transition: 0.3s ease all;
      -webkit-transition: 0.3s ease all;

      ${props =>
        props.disabled &&
        css`
          z-index: 2;
          color: #d6d6d6;
        `}
    }

    /* MOBILE */
    @media (max-width: ${props => props.theme.screenSizes.mobile}px) {
      .validation-error-message {
        width: 100%;
        transform: unset;
        line-height: 1;
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledAppSwitch
