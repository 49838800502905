import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { message, Icon, Select, Tag, Popover } from "antd"
import dayjs from "dayjs"
import moment from "moment"

import * as api from "../../../../api"

import TableStringSearchPopover from "../../../_shared/components/TableStringSearchPopover"
import TableDateSearchPopover from "../../../_shared/components/TableDateSearchPopover"
import PageTitleSection from "../../../_shared/components/PageTitleSection"
import Table from "../../../_shared/components/Table"
import { TOKEN_STATUS } from "../../../_shared/constants"
import AppSelect from "../../../_shared/components/FormItems/AppSelect"
import roles from "../../../_shared/constants/roles.js"
import { useStateValue } from "../../../_shared/context/AppStateStore"
import useRestTable from "../../../_shared/hooks/useRestTable.js"
import AppButton from "../../../_shared/components/AppButton"
import Drawer from "../../../_shared/components/Drawer"
import NewTokensForm from "../../../_shared/components/NewTokensForm"

import AssociateBadgeForm from "./AssociateBadgeForm"

// ----------------------------------------------------------------------------

const Option = Select.Option

// ----------------------------------------------------------------------------

function Tokens(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const [{ token, user }] = useStateValue()

  const availableTokensOptions = {
    pageSize: 1,
  }

  const [
    availableTokens,
    fetchAvailableTokens,
    ,
    ,
    totalAvailablesTokens,
  ] = useRestTable("/tokens/availables", token, availableTokensOptions)

  const assignedTokensOptions = {
    filters: {
      status: "enabled",
    },
  }

  const [
    tokensList,
    fetchAssignedTokens,
    isTokensLoading,
    usersTablePagination,
    totalTokens,
    assignedTokensFiltersDidChange,
    assignedTokenFilters,
  ] = useRestTable("/tokens/assigned", token, assignedTokensOptions)

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [selectedToken, setSelectedToken] = useState(null)
  const [statusFilter, setStatusFilter] = useState("enabled")
  const [isAskMoreFormVisible, setIsAskMoreFormVisible] = useState(false)

  const searchInputRef = useRef()

  const firstAvailableToken =
    totalAvailablesTokens > 0 && availableTokens && availableTokens[0]

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    if (!isDrawerOpen) {
      setSelectedToken(null)
    }
  }, [isDrawerOpen])

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function getPaginationObject() {
    return {
      size: "small",
      total: totalTokens,
      current: usersTablePagination.current,
      pageSize: usersTablePagination.pageSize,
    }
  }

  function handleTableChange(pagination) {
    fetchAssignedTokens({ pagination })
  }

  function openDrawer() {
    setIsDrawerOpen(true)
  }

  function closeDrawer() {
    setIsDrawerOpen(false)
    setSelectedToken({})
  }

  function validateFields(email, name, surname, userId, authenticationId) {
    if (!name) {
      message.error("E' necessario inserire il campo nome")
      return false
    }

    if (!surname) {
      message.error("E' necessario inserire il campo cognome")
      return false
    }

    if (!email) {
      message.error("E' necessario inserire il campo email")
      return false
    }

    if (!userId) {
      message.error("E' necessario inserire il campo Id Utente")
      return false
    }

    if (!authenticationId) {
      message.error("E' necessario inserire il campo Id Autenticazione")
      return false
    }

    return true
  }

  function handleAssociateBadge({ email, name, surname, sendMail, ...tokenBody }) {
    const body = {
      appUser: {
        email,
        name,
        surname,
        role: roles.USER,
        type: "user",
      },
      ...tokenBody,
    }

    const { userId, authenticationId } = tokenBody

    if (!validateFields(email, name, surname, userId, authenticationId)) {
      return
    }

    const tokenId = firstAvailableToken.id

    api
      .associateUserToken(tokenId, body, token, !sendMail)
      .then(() => {
        fetchAvailableTokens()
        fetchAssignedTokens().then(closeDrawer)
      })
      .catch(() =>
        message.error("Non è stato possibile completare l'associazione.")
      )
  }

  function getRowClassName(record, index) {
    switch (record.status) {
      case TOKEN_STATUS.DISABLED: {
        return "disabled-row"
      }
      default: {
        return null
      }
    }
  }

  function handleTokenUpdate({ email, name, surname, ...tokenBody }) {
    const body = {
      ...tokenBody,

      appUser: {
        ...selectedToken.appUser,
        email,
        name,
        surname,
      },
    }

    api
      .updateToken(selectedToken.id, body, token)
      .then((token) => {
        fetchAssignedTokens().then(closeDrawer)
      })
      .catch(() =>
        message.error("Non è stato possibile aggiornare i dati del badge.")
      )
  }

  function handleResendInviationEmail() {
    api
      .sendAppUserInviationEmail(selectedToken.id, token)
      .then(() => message.success("Email inviata con successo."))
      .catch(() =>
        message.error("Non è stato possibile inviare l'email di invito.")
      )
  }

  function handleRowTableClick(token) {
    setSelectedToken(token)

    setIsDrawerOpen(true)
  }

  function toogleDisabledTokens(value) {
    const queryValue = value === "all" ? "" : value

    assignedTokensFiltersDidChange({
      ...assignedTokenFilters,
      status: queryValue,
    })

    setStatusFilter(value)
  }

  function handlePopoverVisibilityChange() {
    setIsAskMoreFormVisible(!isAskMoreFormVisible)
  }

  function handleAskNewTokens(number) {
    const body = {
      tokenNumber: number,
    }

    api
      .adminNewTokenRequest(user.id, body, token)
      .then(() => {
        message.success(
          "La richiesta è stata inoltrata con successo. Verrai contattato quanto prima."
        )
        setIsAskMoreFormVisible(false)
      })
      .catch(() => {
        message.error("Si è verificato un errore. Si prega di riprovare")
        setIsAskMoreFormVisible(false)
      })
  }

  function getFirstAccessPDF() {
    api
      .getFirstAccessPDF(selectedToken.id, token)
      .catch(() =>
        message.error("Non è stato possibile scaricare il documento.")
      )
  }

  // -------------------------------------

  function handleSearch(filterValue, confirm, searchKey) {
    confirm()

    assignedTokensFiltersDidChange({
      ...assignedTokenFilters,
      [searchKey]: filterValue,
    })
  }

  function handleReset(filterKey, clearFilters) {
    const newFilters = { ...assignedTokenFilters }

    delete newFilters[filterKey]

    assignedTokensFiltersDidChange(newFilters)

    clearFilters()
  }

  function getColumnDateSearchProps(searchKey) {
    //eslint-disable
    return {
      // eslint-disable-next-line react/display-name
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <TableDateSearchPopover
            inputRef={searchInputRef}
            value={selectedKeys[0]}
            onChange={(momentDate) => {
              const date = moment(momentDate).format("YYYY-MM-DD")

              setSelectedKeys(date ? [date] : [])
            }}
            onSearch={() => {
              handleSearch(selectedKeys[0], confirm, searchKey)
            }}
            onReset={() => handleReset(searchKey, clearFilters)}
          />
        )
      },
      // eslint-disable-next-line react/display-name
      filterIcon: (filtered) => {
        return <Icon className="filter-icon" type="calendar" />
      },

      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(
            () =>
              searchInputRef &&
              searchInputRef.current &&
              searchInputRef.current.focus(),
            100
          )
        }
      },
    }
    //eslint-enable
  }

  function getColumnTextSearchProps(searchKey) {
    //eslint-disable
    return {
      // eslint-disable-next-line react/display-name
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <TableStringSearchPopover
            inputRef={searchInputRef}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }}
            onPressEnter={() => {
              handleSearch(selectedKeys[0], confirm, searchKey)
            }}
            onSearch={() => {
              handleSearch(selectedKeys[0], confirm, searchKey)
            }}
            onReset={() => handleReset(searchKey, clearFilters)}
          />
        )
      },
      // eslint-disable-next-line react/display-name
      filterIcon: (filtered) => <Icon className="filter-icon" type="search" />,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(
            () =>
              searchInputRef &&
              searchInputRef.current &&
              searchInputRef.current.select(),
            100
          )
        }
      },
    }
    //eslint-enable
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={props.className}>
      <PageTitleSection title="Lista badge" />

      {user.role === roles.ADMIN && (
        <>
          <section className="table-topbar">
            <div className="new-item-container">
              <span>
                <strong>Numero badge disponibili: </strong>
                {totalAvailablesTokens}
              </span>

              <div className="actions-btn-container">
                {totalAvailablesTokens > 0 && (
                  <AppButton onClick={openDrawer} type="button">
                    Assegna badge
                  </AppButton>
                )}

                <Popover
                  content={
                    <NewTokensForm
                      onSubmit={handleAskNewTokens}
                      buttonText="Richiedi"
                      inputPlaceholder="Numero badge richiesti"
                      visible={isAskMoreFormVisible}
                    />
                  }
                  title="Richiedi il numero di badge all'amministratore"
                  trigger="click"
                  visible={isAskMoreFormVisible}
                  onVisibleChange={handlePopoverVisibilityChange}
                >
                  <AppButton type="button" style="ghosted">
                    Richiedi nuovi badge
                  </AppButton>
                </Popover>
              </div>
            </div>

            <div className="filters-row">
              <AppSelect
                value={statusFilter}
                onChange={toogleDisabledTokens}
                defaultValue="enabled"
                dropdownMatchSelectWidth={false}
              >
                <Option value="all">Tutti</Option>
                <Option value="enabled">Abilitati</Option>
                <Option value="disabled">Disabilitati</Option>
              </AppSelect>
            </div>
          </section>
        </>
      )}

      <Table
        columns={getColumns(
          handleRowTableClick,
          getColumnTextSearchProps,
          getColumnDateSearchProps
        )}
        dataSource={tokensList}
        loading={isTokensLoading}
        pagination={getPaginationObject()}
        onChange={handleTableChange}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => handleRowTableClick(record),
          }
        }}
        rowClassName={getRowClassName}
      />

      <Drawer open={isDrawerOpen} onCancel={closeDrawer}>
        <AssociateBadgeForm
          onSubmit={
            (selectedToken && handleTokenUpdate) || handleAssociateBadge
          }
          sendInviationEmail={handleResendInviationEmail}
          token={selectedToken || firstAvailableToken}
          getFirstAccessPDF={getFirstAccessPDF}
        />
      </Drawer>
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

Tokens.propTypes = {
  className: PropTypes.string.isRequired,
}

Tokens.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledTokens = styled(Tokens)`
  & {
    .table-topbar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      margin: 25px 0;

      .more-tokens-btn {
        color: #0081ff;
        font-weight: 600;
      }

      .filters-row {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .new-item-container {
        display: flex;
        flex-direction: column;

        .actions-btn-container {
          display: flex;
          flex-direction: row;
          align-items: center;

          > * {
            margin-right: 10px;
          }
        }

        button {
          margin-top: 12px;
        }
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledTokens

// ----------------------------------------------------------------------------
// Table columns
// ----------------------------------------------------------------------------

function getColumns(
  handleRowEdit,
  getColumnTextSearchProps,
  getColumnDateSearchProps
) {
  return [
    {
      title: "Assegnato a",
      dataIndex: "name",
      key: "name",
      render: (name, record, index) => {
        return `${record.appUser.name || ""} ${record.appUser.surname || ""}`
      },
      ...getColumnTextSearchProps("name"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (name, record, index) => {
        return `${record.appUser.email || ""}`
      },
      ...getColumnTextSearchProps("email"),
    },
    {
      title: "Primo accesso",
      dataIndex: "firstAccessDate",
      key: "firstAccessDate",
      render: (firstAccessDate, record, index) => {
        const formattedDate =
          firstAccessDate && dayjs(firstAccessDate).format("DD/MM/YYYY")

        return `${formattedDate || ""}`
      },
      ...getColumnDateSearchProps("first-access"),
    },

    {
      title: "Device autorizzato",
      dataIndex: "deviceInfo",
      key: "deviceInfo",
      //eslint-disable
      render: (deviceInfo, record, index) => {
        if (record.deviceId === null && deviceInfo === null) {
          return <span>Resettato</span>
        } else {
          return (
            deviceInfo && (
              <div>
                {record && record.deviceId && (
                  <div className="device-id">{`${record.deviceId || ""}`}</div>
                )}

                {deviceInfo && <span>{`${deviceInfo || ""}`}</span>}
              </div>
            )
          )
        }
      },
      ...getColumnTextSearchProps("device"),
      // eslint-enable
    },
    {
      title: "",
      dataIndex: "status",
      key: "edit",
      // eslint-disable
      render: (status, record, index) => {
        return (
          status &&
          status === TOKEN_STATUS.DISABLED && (
            <Tag color="red">DISABILITATO</Tag>
          )
        )
      },
      // eslint-enable
    },

    {
      title: "",
      dataIndex: "email",
      key: "edit",
      // eslint-disable
      render: (name, record, index) => {
        return (
          record &&
          record.status &&
          record.status !== TOKEN_STATUS.DISABLED && (
            <a>
              <Icon type="edit" onClick={() => handleRowEdit(record)} />
            </a>
          )
        )
      },
      // eslint-enable
    },
  ]
}
